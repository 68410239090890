<template>
  <div class="clients-form">
    <h2 class="edit-text">{{ createOrEdit }}</h2>
    <div>
          <div class="content">
            <md-card>
              <form action="" @submit.prevent="saveClient">
                <md-card-header>
                  <div class="md-title"><b></b></div>
                  <div class="md-subhead"></div>
                </md-card-header>

                <md-card-content>
                  <p>{{ $t('name') }}</p>
                  <md-field md-inline>
                    <md-input v-model="name" :class="$route.name === 'clients-edit' ? 'name-disable' : ''" required :disabled="$route.name === 'clients-edit'" :placeholder="`${$t('Name')}*`"></md-input>
                  </md-field>
                </md-card-content>

                <md-card-content>
                  <p>{{ $t('status') }}</p>
                  <select name="role" class="select-role" v-model="selected_status">
                    <option :selected="selected_status === status.id" v-for="status in statuses" :key="status.id" :value="status.id">{{ $t(status.name) }}</option>
                  </select>
                </md-card-content>
                <md-card-content v-for="(item, i) in managers" :key="i">
                  <p>{{ $t('clientManager') }}</p>
                    <select name="role" class="select-role" v-model="item.manager.id">
                      <option :selected="user.id" v-for="user in user_list" :key="user.id" :value="user.id">{{ user.name }}</option>
                    </select>
                  <div v-if="managers.length === i + 1" class="btn-plus pointer"
                       @click="addManagerSelect"
                  >
                    <img src="../../assets/images/plus.svg"/>
                  </div>

                  <div class="btn-close pointer" v-if="i" @click="removeManagerSelect(item)">
                    &#10006;
                  </div>
                </md-card-content>
                <md-card-content>
                  <p>{{ $t('Description') }}</p>
                  <md-field md-inline>
                    <md-textarea class="description-textarea" v-model="description"></md-textarea>
                  </md-field>
                </md-card-content>

                <div class="actions">
                  <md-button type="submit" class="md-raised save"><span>{{ $t('save') }}</span></md-button>
                  <md-button class="md-raised cancel">
                    <router-link to="/clients"><span>{{ $t('cancel') }}</span></router-link>
                  </md-button>
                </div>
              </form>
            </md-card>

          </div>
          <div>
            <Messages :messages="messages"></Messages>
            <Errors :errors="errors"></Errors>
          </div>
    </div>
  </div>
</template>

<script>
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import {userPermissions} from '@/mixins/userPermissions';
import Errors from "@/components/Errors"
import Messages from "@/components/Messages"
import {mapMutations, mapActions} from "vuex";

export default {
  name: "clients-form",
  mixins: [
    printArray,
    errors,
    messages,
    userPermissions
  ],
  components: {
    Errors,
    Messages
  },
  data: () => ({
    name: '',
    status: '',
    description: '',
    roles: [],
    permissions: [],
    role_name: '',
    client: '',
    selected_role: '',
    selected_status: '',
    managers:[],
    user_list: [],
    manager_ids:[],
    statuses: [
      {id: 1, name: 'Active'},
      {id: 0, name: 'Inactive'},
    ]
  }),

  computed: {
    path() {
      let pathName;
      if(this.$route.name === 'clients-create'){
        pathName = "/clients/create"
      }else if(this.$route.name === 'clients-edit'){
        pathName = `/clients/edit/${this.$route.params.id}`
      }
      return pathName
    },

    createOrUpdate() {
      return (Object.keys(this.client).length !== 0) ? '/clients/' + this.client.id + '/update' : '/clients/create';
    },

    createOrEdit() {
      let name;
      if (this.$route.name === 'clients-create') {
        let dynamicName = "Create Client"
        name = this.$t(dynamicName)
      } else if (this.$route.name === 'clients-edit') {
        let dynamicName = "Edit Client"
        name = this.$t(dynamicName)
      }
      return name
    },

    disabledTab() {
      if(this.name && (this.selected_status || this.selected_status === 0)) {
        return false
      } else return true
    }
  },

  watch:{
    $route() {
      this.clearErrors();
      this.clearMessages()
    },
    selected_role: {
      immediate: true,
      handler() {
        this.roles.filter(role => {
          if(role.id === this.selected_role) {
            this.permissions = role.permissions
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations(['setAddedClientId']),

    addManagerSelect() {
      this.managers.push({
        manager: {id: ''}
      })
    },
    removeManagerSelect(item) {
      const index = this.managers.indexOf(item)
      this.managers.splice(index, 1)
    },
    saveClient() {
      let url = process.env.VUE_APP_API_URL + this.createOrUpdate;

      this.clearErrors();
      this.clearMessages();
      this.managers.map(item => {
        if(item.manager.id === "") {
          return
        }
        else {
          this.manager_ids.push(item.manager.id)
        }
      })
      let data = {
        name: this.name,
        status: this.selected_status,
        description: this.description,
        manager_ids: this.manager_ids
      };

      this.$axios
          .post(url, data)
          .then(response => {
            if (response.data.status === 'success') {
              this.addMessage(response.data.message);
            } else {
              this.setErrors(response.data.message);
            }
            this.$router.push({name: 'clients-list'});
          })
          .catch(err => {
            this.setErrors(err.response.data.errors);
          })

    },

    async getRoles() {
      await this.$axios
          .get(process.env.VUE_APP_API_URL + "/roles/list")
          .then(response => {
            this.roles =  response.data.data
          })
          .catch(err => {
            this.setErrors(err.response.data.errors);
          })
    },

    async  getClient(id) {
      let client = {};
      await this.$axios
          .get(process.env.VUE_APP_API_URL + '/clients/' + id + '/get')
          .then(response => {
            client = response.data.data;
            this.managers = response.data.data.managers
          })
          .catch(err => {
            this.setErrors(err.response.data.errors);
            this.$router.push({name: 'clients-list'});
          })

      return client;
    },
    ...mapActions(['getUsersList']),
    getUser() {
      this.getUsersList().then(data => {
          this.user_list = data.filter(item => item.role_name === "manager")
      })
    }
  },
  async mounted() {
    let clientId = this.$route.params.id;
    this.getUser()

    await this.getRoles();

    if (clientId) {
      this.client = await this.getClient(clientId);
      this.name = this.client.name;
      this.selected_status = this.client.status;
      this.description = this.client.description;
    }
      this.addManagerSelect()
  },

}
</script>

<style lang="scss">
.clients-form {
  .md-card-content  {
    margin-bottom: 20px;
  }
  margin-left: 21px;
  p {
    margin: 0;
  }

  .select-wrap {
    margin-bottom: 15px;
  }

  .description-textarea {
    padding-left: 22px!important;
  }

  .md-tabs.md-theme-default .md-tabs-navigation{
    background: unset;
    border-bottom: 2px solid grey;
  }

  .md-tabs.md-theme-default .md-tabs-indicator{
    background-color: #054F63!important;
    top: 48px;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .name-disable{
    background: #939393;
    opacity: .5;
  }

  .md-content.md-theme-default{
    background: unset;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .select-role {
    width: 63%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 15px;
    height: 50px;
    font-size: 16px;
    padding: 0 0 0 25px;

    option {
      height: 50px;
    }
  }
  .md-textarea {
    font-size: 16px;
  }

  .content {
    display: flex;
  }

  .actions {
    margin: 0 0 0 105px;

    button{
      width: 119px;
    }

    .save {
      background: #EC8B31!important;
      border-radius: 6px;

      span {
        color: white;
      }
    }

    .cancel {
      border-radius: 6px;

      a{
        color: black;
      }
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 12px 32px;
    }
  }

  .md-ripple {
    .md-icon.md-theme-default.md-icon-font {
      display: none !important;
    }
  }

  .md-menu.md-select {
    padding-left: 25px;
    padding-top: 5px;
  }

  .md-card-content {
    margin-left: 21px;
    display: flex;
    padding: 0 !important;
  }

  .edit-text {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
  }

  .md-field:after, .md-field:before {
    position: unset !important;
  }

  .md-card-content {
    width: 100%;

    p {
      padding-top: 17px;
      width: 80px;
      text-align: right;
    }

    .md-has-password {
      margin-left: -20px;
    }
  }

  .md-button {
    height: 48px;
  }

  .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before{
    border-color: transparent!important;
  }

  .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after{
    border-color: transparent!important;
  }

  .md-card.md-theme-default {
    background: unset;
    width: 98%;
  }

  .md-card {
    box-shadow: unset !important;
  }

  .md-select-value {
    position: absolute;
    top: 6px;
    left: 25px;
  }

  .md-field {
    width: 63%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 4px 0 0 15px;
    padding: 0;

    .md-input {
      height: 50px !important;
      padding: 0 !important;
      padding-left: 25px !important;
      font-size: 16px;
    }

    label {
      position: absolute;
      top: 14px;
      padding-left: 25px;
    }
  }

  .permissions{

    h5{
      margin-left: 186px;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 34px;
      color: #000000;
    }

    ul{
      margin-left: 150px;
      li{
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
}
.btn-plus {
  margin-left: 12px;
  margin-top: 10px;
}
.btn-close {
  margin-left: 12px;
  margin-top: 10px;
  cursor: pointer;
}
</style>